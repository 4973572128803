import { handleErrorWithSentry, Replay } from "@sentry/sveltekit";
import * as Sentry from '@sentry/sveltekit';
import { RewriteFrames } from "@sentry/integrations";
import { PUBLIC_SENTRY_DNS } from "$env/static/public";

Sentry.init({
  dsn: PUBLIC_SENTRY_DNS,
  tracesSampleRate: 0.2,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  
  // If you don't want to use Session Replay, just remove the line below:
  // integrations: [new Replay(), new RewriteFrames()],
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
